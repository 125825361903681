import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import '../styles/components/aboutpage.scss';
import Pagetitle from "../components/Pagetitle";
import Blocktitle from "../components/Blocktitle";
import fannyImg from "../images/fanny_nieuw.jpg";
import SearchLottie from '../components/SearchLottie';
import SkillsLottie from '../components/SkillsLottie';
import WorkLottie from '../components/WorkLottie';
import cvPdf from "../files/FannyBenisek-Curriculumvitae(EN).pdf";
import { motion } from "framer-motion"
import Bodytext from "../components/Bodytext";
import {GatsbySeo} from "gatsby-plugin-next-seo";
import {Helmet} from "react-helmet";


export default function About() {
  return (
    <div className="scroll-content">
      <Helmet>
        <meta charSet="utf-8" />
        <title>About me</title>
      </Helmet>
      <GatsbySeo noindex={true} />
      <div className="page about">
        <Header />
        <section className="header--intro container">
          <div className="header-img-wrapper">
            <div className="header--inner">
              <img className="header--img lazyload" data-src={fannyImg} alt="Fanny Benisek" />
            </div>
          </div>
          <div className="page--description--wrapper">
            <Pagetitle pageTitle="Hi, I’m Fanny! UX/UI designer and front-end developer from Ghent!" />
            <Bodytext bodyText="I’m a UX/UI designer and developer who studied web -and interaction design at SOA/KASK Ghent before taking a detour getting a degree in Japanese language and culture." />
            <Bodytext bodyText="During my career I gained both design and front-end development experience. This allows me to keep in mind visual, technical and user-centered aspects of projects." />
            <Bodytext bodyText="I enjoy making lino print art, taking care of my growing plant collection, going to concerts and traveling." />
          </div>
        </section>
        <section className="what--wrapper about--layout container">
          <aside className="layout--about--sidebar sidebar">
            <SearchLottie/>
            <Blocktitle blockTitle="What job am I looking for?" />
          </aside>
          <div className="layout--about--text">
            <Bodytext bodyText="I’m looking to work as a UX/UI designer either in Ghent with work from home opportunities or full remote. I’d like to be part of a company that values their employees as people and invests time and effort in creating an enjoyable work environment." />
            <motion.a whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }} href={cvPdf} className="btn--download" download>Download CV</motion.a>
          </div>
        </section>
        <section className="work--wrapper about--layout container">
          <aside className="layout--about--sidebar sidebar">
            <WorkLottie/>
            <Blocktitle blockTitle="Work experience" />
          </aside>
          <div className="layout--about--text work">
          <div className="work--block">
              <h3 className="work--title">Mono</h3>
              <h6 className="work--year">February 2021 - Oktober 2021</h6>
              <ul className="work--role">
                <li className="work--role--item">UX/UI designer</li>
                <li className="work--role--item">Front-end developer</li>
              </ul>
            </div>
            <div className="work--block">
              <h3 className="work--title">STRAK</h3>
              <h6 className="work--year">August 2020 - February 2021</h6>
              <ul className="work--role">
                <li className="work--role--item">Front-end developer</li>
                <li className="work--role--item">UI designer</li>
              </ul>
            </div>
            <div className="work--block">
              <h3 className="work--title">EntityOne</h3>
              <h6 className="work--year">March 2018 - June 2020</h6>
              <ul className="work--role">
                <li className="work--role--item">Front-end developer</li>
                <li className="work--role--item">Web & UX/UI designer</li>
              </ul>
            </div>
            <div className="work--block">
              <h3 className="work--title">CreaThing</h3>
              <h6 className="work--year">January 2016 - March 2018</h6>
              <ul className="work--role">
                <li className="work--role--item">Web Designer</li>
                <li className="work--role--item">Front-end developer</li>
              </ul>
            </div>
          </div>
        </section>
        <section className="skills--wrapper about--layout container">
          <aside className="layout--about--sidebar sidebar">
            <SkillsLottie/>
            <Blocktitle blockTitle="My main skills" />
          </aside>
          <div className="layout--about--text">
            <div className="skills--list">
              <h3 className="skills--list--title">UX/UI Design</h3>
              <h3 className="skills--list--title">Web Design</h3>
              <h3 className="skills--list--title">Front-end Development</h3>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}